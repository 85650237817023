* {
  box-sizing: border-box;
  background: #2c2c2c;
  color: white;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  width: 100vw;
  min-height: 100vh;
  height: auto;
  overflow-x: hidden;
}

a {
  text-decoration: none;
}
a:visited {
  color: lightblue;
}

#root {
  height: 100%;
}
